<template>
    <div>
        <h1 class="page__title">Отзывы</h1>
        <div class="actions__container">
            <div>
                <router-link class="btn btn-primary w-40 inline-block mr-1 mb-2" :to="{ name: 'review-create' }">
                    Создать
                </router-link>
            </div>
        </div>

        <ContentSearch />

        <ContentTable
            :table-actions="tableActions"
            :headers="tableHeaders"
            :table-rows="tableData"
            :meta="pagination"
            :loading="loading"
            @edit="editAction"
            @published="publishedAction"
            @delete="deleteAction"
        />

        <PopupDeleteModal
            :is-open="modalDeleteOpen"
            :entity-name="modalDeleteCaption"
            :modal-data="modalDeleteRow"
            @update:isOpen="modalDeleteOpen = $event"
            @deleteModalAccepted="onDeleteModalAccepted"
        />
    </div>
</template>

<script>
import { errorResponse } from '@/mixins/form/form-mixin';
import ContentTable from '@/components/tables/ContentTable';
import PopupDeleteModal from '@/components/modals/PopupDeleteModal';
import ContentSearch from '@/components/base/ContentSearch';
import { pageMixin } from '@/mixins/pageMixin';

export default {
    components: { ContentSearch, PopupDeleteModal, ContentTable },
    mixins: [errorResponse, pageMixin],
    async beforeRouteUpdate(to, from, next) {
        await this.fetchData({ ...to.query });
        next();
    },
    data() {
        return {
            modalDeleteOpen: false,
            modalDeleteCaption: null,
            modalDeleteRow: null,
            modalCallback: null,
            modalData: null,
            tableActions: [
                { name: 'edit', title: 'редактировать', icon: 'Edit2Icon', cbName: 'edit' },
                {
                    name: 'published',
                    title: 'Опубликовать',
                    title2: 'Снять с публикации',
                    class: 'bg-primary-10',
                    icon: 'toggle',
                    cbName: 'published',
                },
                { name: 'delete', title: 'удалить', class: 'bg-red-400', icon: 'Trash2Icon', cbName: 'delete' },
            ],
            tableHeaders: [
                { field: 'id', label: '#' },
                { field: 'user_name', label: 'Фио' },
                { field: 'ship_name', label: 'Корабль' },
                { field: 'review_date', label: 'Дата отзыва' },
                { field: 'images_count', label: 'Фото' },
                { field: 'rating', label: 'Оценка' },
            ],
            loading: false,
            tableData: [],
            pagination: null,
        };
    },
    async created() {
        this.$store.commit('main/setPageHeader', null);
        await this.fetchData();
    },
    methods: {
        async fetchData(params = { ...this.$route.query }) {
            try {
                this.loading = true;
                const { data } = await this.axios.get('/reviews', { params });
                this.tableData = data.data.map((r) => {
                    return {
                        ...r,
                        ...{ images_count: r.images && r.images.length ? r.images.length : 'Нет' },
                    };
                });
                this.pagination = data.meta;
                this.loading = false;
            } catch (ex) {
                this.loading = false;
                console.log('cant fetch data: ' + ex);
            }
        },
        editAction(row) {
            // this.$router.push({ name: 'review-edit', params: { id: row.id } });
            this._pMTransitionToEditPageWithQuery('review-edit', { id: row.id });
        },
        async publishedAction(row) {
            this.loading = true;

            try {
                const publishedVal = !row.published;
                const published = publishedVal ? 1 : 0;
                await this.axios.post(
                    `/reviews-toggle-published/${row.id}`,
                    { published },
                    {
                        params: { _method: 'patch' },
                    }
                );
                await this.fetchData(this.$route.query);
                this.loading = false;
            } catch (ex) {
                this.loading = false;
                console.log('cant toggle publish: ' + ex);
            }
        },
        deleteAction(row) {
            this.modalDeleteRow = row;
            this.modalDeleteCaption = `отзыв ${row.id}`;
            this.modalDeleteOpen = true;
        },
        onDeleteModalAccepted(data) {
            this.axios
                .delete('/reviews/' + data.id)
                .then(() => {
                    this.fetchData(this.$route.query);
                })
                .catch((ex) => {
                    this.errorResponse(ex);
                })
                .finally(() => {
                    this.modalDeleteOpen = false;
                });
        },
    },
};
</script>

<style></style>
